import * as React from 'react';
import * as styles from './rethinking-recruiting.module.css';
import ShowcasePage from './showcase/showcase-page';
import SectionHeader from './showcase/section-header';
import persona1 from '../images/persona1.svg';
import persona2 from '../images/persona2.svg';
import persona3 from '../images/persona3.svg';
import lofi1 from '../images/lofi1.svg';
import lofi2 from '../images/lofi2.svg';
import lofi3 from '../images/lofi3.svg';
import lofi4 from '../images/lofi4.svg';
import hifi1 from '../images/hifi1.svg';
import hifi2 from '../images/hifi2.svg';
import hifi3 from '../images/hifi3.svg';
import hifi4 from '../images/hifi4.svg';
import hifi5 from '../images/hifi5.svg';
import { COLORS } from '../constants';
import { Box } from '@mui/material';

function RethinkingRecruiting() {
  return (
    <ShowcasePage>
      <Box component="h1" mb={4} sx={{ color: COLORS.showcase }}>
        Rethinking Recruiting
      </Box>
      <SectionHeader>The problem</SectionHeader>
      <p className={styles.highlight}>
        Applying for jobs <span className={styles.highlightWord}>sucks</span>.
      </p>
      <p className={styles.paragraph}>
        We’ve all been there. You spend hours upon hours, typically across
        multiple months, applying to a seemingly endless stream of jobs -
        writing cover letters, modifying your resume, tweaking your LinkedIn
        profile, filling out online forms, searching for referrals - only to get
        rejected from almost every one that you apply to. It’s disheartening.
      </p>
      <p className={styles.paragraph}>
        Websites like LinkedIn and Indeed are supposed to help, giving you
        personalized recommendations and allowing you to set up alerts on
        relevant postings, but oftentimes these aren’t accurate enough. I can’t
        tell you how many times I got sent job recommendations for things like
        “head of social media marketing” and “senior analyst” when I was looking
        for early-level career postings pertaining to market research. And even
        the suggestions that were a good fit for me often lead to complete dead
        ends. It seemed hopeless. And it wasn’t just me. Even my aunt, who has
        30 years of professional experience, including management, said she had
        spent several months applying to nearly 100 jobs before she found the
        one.
      </p>
      <p className={styles.paragraph}>
        That got me thinking - why is the job hunt such a shot in the dark? Of
        the many aspects of human life that technology has improved, it seems
        ridiculous that we still spend so much time and energy on a process that
        almost everyone must go through, with a very low success rate -
        according to the site{' '}
        <a
          className="link"
          href="https://www.hirelehigh.com/post/how-many-applications-does-it-take-to-get-a-job#:~:text=Recent%20stats%20(from%20Talent%20Works,to%20get%20one%20job%20offer."
        >
          Hire Lehigh
        </a>
        , it takes a professional between 100 and 200 job applications to get
        one offer. This is a huge waste of time and resource - in the time that
        professional spent applying to 99 of those jobs, he/she could have
        already been contributing to the role they would ultimately end up in.
      </p>
      <p className={styles.paragraph}>
        Knowing how many applications one professional must send in to land a
        gig, there must also be a large influx of applications for each job
        posting that recruiters ultimately have to weave through. Some advances
        in automated resume readers have helped this, but these tools end up
        widening the gap for people from diverse backgrounds, who these systems
        commonly discriminate against.
      </p>
      <p className={styles.paragraph}>
        There had to be a way of improving and simplifying the job process, for
        both the applicant and the recruiter. That’s what led me to my concept
        redesign of the online job application portal, which I am calling{' '}
        <span className={styles.highlightWord}>Rethinking Recruiting</span> - or
        “RR” for short.
      </p>

      <SectionHeader>How it works</SectionHeader>
      <ul className={styles.list}>
        <li>
          Uses machine learning to understand who is most likely to get the job
        </li>
        <li>
          Curates applicants’ suggestions to show jobs where, based on their
          information, they have the highest chances of success
        </li>
        <li>
          Decreases the amount of applicants recruiters must review for each
          listing
        </li>
        <li>
          Calls out potential biases in a company’s recruitment against people
          from diverse backgrounds
        </li>
      </ul>
      <p className={styles.paragraph}>
        The thought behind RR is that it will use machine learning to better
        curate the job application process, for both the job seeker and the
        recruiter. RR will take user data - people’s educational background,
        years of experience, career field, certifications, demographics, etc -
        along with their current roles and hopeful roles and “learn” who is
        likely to get what jobs. When people apply and get accepted to or
        rejected from jobs, RR will start compiling any commonalities among
        these applicants to create profile information on who each role or each
        company is hiring.
      </p>
      <p className={styles.paragraph}>
        For example, RR learns that company X rejects 95% of people who have
        less than a bachelor’s degree, so RR will no longer suggest company X
        roles to those who have not graduated from college (and are not about to
        graduate). Or, RR learns that 88% of people who got hired for the role
        “senior business analyst” have at least 5 years of experience in a
        business analyst role, and 98% have a bachelor’s degree. RR will now
        suggest Company Y’s senior business analyst posting to those with a
        college degree and 5+ years of experience. Perhaps RR learns that
        Company Z’s frequently posted “entry-level software developer” job
        almost always hires people 2 or less years out of college with a degree
        in either computer science, computer engineering, or IT. RR now knows to
        only show this listing to people who fit those two criteria. Maybe RR
        notices that Company Z also hires ex-convicts while Company Y never does
        - that information would change who is shown listings from each company,
        as well.
      </p>
      <p className={styles.paragraph}>
        The goal of RR is to save time for both parties involved in the process.
        With this process, applicants get fewer recommendations, but each one is
        tailored with all of their background credentials and demographics in
        mind, and the likelihood that they will get an offer is much higher.
        Recruiters who use RR will receive less applicants, but each one has a
        high likelihood of fitting their typical hire demographics.
      </p>
      <p className={styles.paragraph}>
        Of course, RR isn’t for everyone. It isn’t for the “diamond in the
        rough” individual who, without a college degree, was able to climb the
        ladder of a small startup-turned-corporation as a sales leader, and is
        now searching for an equivalent sales manager position at a new company.
        It isn’t for those niche cases where someone may not fit the typical
        criteria, but has such a compelling experience that they get the job
        anyway.
      </p>
      <p className={styles.paragraph}>
        But RR is for the typical professional who is tired of wasting time on
        fruitless job applications, or the overworked recruiter who doesn’t want
        to read through 150 resumes only to toss out two-thirds of them for not
        meeting basic conditions.
      </p>
      <p className={styles.paragraph}>
        RR can also help companies see when their recruitment policies and
        practices are creating undiverse organizations, and work to fix them.
        For example, RR catches that Company X rejects 25% more women than men,
        even when their backgrounds are equivalent. Or RR reports that Company Y
        turns down almost all applicants from historically black colleges and
        universities, while not turning down as many applicants from an
        equivalent state school. Having the clear-cut statistics that only a
        system like RR could find would help companies bridge these previously
        unseen gaps in their inclusion efforts.
      </p>

      <SectionHeader>Asking the right questions</SectionHeader>
      <p className={styles.paragraph}>
        The idea seemed like a winner, but I have to be sure that there is a
        broader use case for RR before I actually implement it. To design a good
        product, I need to draw from my target audience. These are the types of
        questions I would ask to get a sense for their wants, needs, and gaps:
      </p>
      <ol className={styles.list}>
        <li>How do you feel when applying for a new job?</li>
        <li>What are your top pain points when applying for a new job?</li>
        <li>
          When looking for a new job, what resources do you typically turn to?{' '}
        </li>
        <li>
          What are the top websites or online sources you typically use when
          searching for jobs? Please list up to three.{' '}
        </li>
        <li>What do you like about each of those? Why is X your top choice?</li>
        <li>Is there anything you dislike about these websites?</li>
        <li>
          How many job applications do you typically submit during your job
          search?
        </li>
        <li>
          When attempting to get a new job, how many hours a week do you
          typically spend searching for jobs to apply to online?
        </li>
        <li>
          When attempting to get a new job, how many hours a week do you
          typically spend writing materials for job applications (i.e. resumes,
          cover letters, etc.) and filling out applications?
        </li>
        <li>
          Walk me through your job search process. Start at the beginning -
          deciding to look for a new job - and talk me through what steps you
          take until you accept an offer.{' '}
        </li>
        <li>
          What are the most important criteria you look for in a job posting?
        </li>
      </ol>

      <SectionHeader>Personas</SectionHeader>
      <p className={styles.paragraph}>
        In order to better understand how I should design RR, I needed to create
        personas based off of the research. Here are three examples of the
        personas I think the design of RR should keep in mind:
      </p>
      <img className={styles.image} src={persona1} alt="Persona 1" />
      <img className={styles.image} src={persona2} alt="Persona 2" />
      <img className={styles.image} src={persona3} alt="Persona 3" />

      <SectionHeader>Low fidelity designs</SectionHeader>
      <p className={styles.paragraph}>
        I next wanted to sketch out some general ideas of the layout and
        features would look like on my platform, resulting in some basic low
        fidelity concept designs:
      </p>

      <p className={styles.imageTitle}>The Login page</p>
      <img className={styles.image} src={lofi1} alt="Low fidelity design 1" />
      <p className={styles.imageTitle}>The Match page</p>
      <img className={styles.image} src={lofi2} alt="Low fidelity design 2" />
      <p className={styles.imageTitle}>The Sign Up page</p>
      <img className={styles.image} src={lofi3} alt="Low fidelity design 3" />
      <p className={styles.imageTitle}>The Applications page</p>
      <img className={styles.image} src={lofi4} alt="Low fidelity design 4" />

      <SectionHeader>High fidelity designs</SectionHeader>
      <p className={styles.paragraph}>
        Lastly, I utilized all of the information I had collected and the
        drafting I had done during the previous stages to build out some of the
        key pages of the final product in Figma. I am a big fan of unique yet
        clean fonts and utilizing a bold color balance with white space, so
        that’s why I designed the general look and feel of the home/login page
        the way I did.
      </p>
      <p className={styles.imageTitle}>The Login page</p>
      <img className={styles.image} src={hifi1} alt="High fidelity design 1" />
      <p className={styles.paragraph}>
        I wanted to make it easy to see exactly where users should go if they
        wanted to either login or sign up, so I made it easy and clear to
        navigate between the two (with the default set to the login function).
        So many times I’ve had to search for the sign-up page - it’s never made
        sense to me that it wouldn’t be on the first page. I wanted to
        prioritize ease of sign-up on my home page, since I will rely heavily on
        lots of people signing up upfront in order for the machine learning
        aspect of RR to have a high enough sample to accurately predict matches.
      </p>
      <p className={styles.imageTitle}>
        I then carried over that simplicity and ease of access into the sign up
        functionality:
      </p>
      <img className={styles.image} src={hifi2} alt="High fidelity design 2" />
      <img className={styles.image} src={hifi3} alt="High fidelity design 3" />
      <p className={styles.paragraph}>
        I particularly like using darkened bubbles of the same color to show
        which option has been selected, as I think that’s a cleaner way of
        demonstrating selection without removing the other choices (in case of
        error).
      </p>
      <p className={styles.imageTitle}>
        Next, I tackled one of the main pages - the Applications page:
      </p>
      <img className={styles.image} src={hifi4} alt="High fidelity design 4" />
      <p className={styles.paragraph}>
        On this page, it was important that the design looked clean, so as not
        to bury the important information. Yet, I also had a lot of
        functionality to add, putting the Applications page at risk of clutter.
        I tried to minimize this by using whitespace, color, larger font sizes,
        and rectangular-based organization. I also wanted to carry the theme of
        darkening the selected box that I utilized in the login and sign up
        pages over into the navigation bar at the top.
      </p>
      <p className={styles.imageTitle}>
        Lastly, I designed another important feature, the Matches page:
      </p>
      <img className={styles.image} src={hifi5} alt="High fidelity design 5" />
      <p className={styles.paragraph}>
        I attempted to use the same concepts and principles that I did in the
        Applications page here as well, making use of whitespace, color, font
        size, and rectangles. This page had even more content, so it was even
        more critical that I found the right balance so as to not overwhelm the
        user while still providing all of the relevant information and features.
      </p>

      <SectionHeader>Conclusion</SectionHeader>
      <p className={styles.paragraph}>
        RR may not exist yet, but hopefully one day it will! Ultimately, taking
        my project through all of the important UX processes - identifying the
        problem, outlining the solution, designing the research, building the
        personas, drafting lo-fi sketches, and creating hi-fi pages - was an
        important exercise for me in getting the full UX experience. I have
        learned a lot through this project, which will help me do an even better
        job on my next one. I am excited to take what I have learned through
        this personal experiment and build upon it further in graduate school!
      </p>
      <p className={styles.listHeader}>
        What I would do differently next time:
      </p>
      <ul className={styles.list}>
        <li>
          Give myself more time. This project would have turned out better had I
          not rushed myself.
        </li>
        <li>
          Better plan the features I would need to include before beginning the
          design in Figma.
        </li>
        <li>Test the design on real people. </li>
        <li>Design a logo.</li>
      </ul>
    </ShowcasePage>
  );
}

export default RethinkingRecruiting;
